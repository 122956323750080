import React, { useEffect, useState } from "react";
import { Card, Spin, Alert, Divider } from "antd";
import moment from "moment";
import reportSvc from "../services/reportSvc";
import { useLocation } from "react-router-dom";
import LaporanInformationCard from "../components/LaporanInformationCard";

moment.tz.setDefault("Asia/Bangkok");

const pelanggan = {
  cilegon: 1,
  serang: 2,
  tangerang: 3,
  bekasi: 4,
  jatinegara: 5,
  rawamangun: 6,
  karawang: 7,
  testing: 8,
  daanmogot: 9,
  malang: 10,
  jakartapusat: 11,
  harmoni: 12,
  kramat: 13,
  pecenongan: 14,
  jababeka: 15,
  indramayu: 16,
  tanjungkarang: 17,
  klaten: 18,
  ugm: 19,
};

export default function ReportPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [reportExports, setReportExport] = useState([]);
  const [daysInRange, setDaysInRange] = useState(0);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const query_pel = queryParams.get("cabang");
  const id_pelanggan = pelanggan[query_pel] || null;

  const updateDaysInRange = () => {
    const start = moment(startDate);
    const end = moment(endDate);
    const days = end.diff(start, "days") + 1;
    setDaysInRange(days);
  };

  const fetchReportsExport = async () => {
    if (!id_pelanggan) {
      return;
    }
    try {
      setIsLoading(true);
      const respdata = await reportSvc.getReportExportDailyNoAuth({
        params: {
          start_date: startDate,
          end_date: endDate,
          id_pelanggan,
        },
      });
      if (respdata.status === 200) {
        setReportExport(respdata.data.data);
      }
    } catch (err) {
      console.error("Failed to fetch reports", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    updateDaysInRange();
  }, [startDate, endDate]);

  useEffect(() => {
    if (id_pelanggan) {
      fetchReportsExport();
    }
  }, [id_pelanggan, startDate, endDate]);

  const generateDateLabels = (startDate, daysInRange) => {
    const labels = [];
    for (let i = 0; i < daysInRange; i++) {
      const date = moment(startDate).add(i, "days");
      labels.push(date.format("D MMM"));
    }
    return labels;
  };

  const renderTableHeaders = () => {
    return (
      <>
        <tr
          style={{
            position: "sticky",
            top: 0,
            backgroundColor: "#f5f5f5", // Background color to prevent overlap
            zIndex: 2, // Ensure it stays above other rows
          }}
        >
          <th
            style={{
              textAlign: "center",
              fontWeight: "bold",
              border: "1px solid #ccc",
              padding: "8px",
            }}
            colSpan={3}
          >
            INFORMASI ATM
          </th>
          <th
            style={{
              textAlign: "center",
              fontWeight: "bold",
              border: "1px solid #ccc",
              padding: "8px",
            }}
            colSpan={daysInRange * 3}
          >
            TANGGAL
          </th>
        </tr>
        <tr
          style={{
            position: "sticky",
            top: 40, // Adjust this value if header height changes
            backgroundColor: "#f5f5f5", // Match header background
            zIndex: 1, // Ensure it stays just below the main header
          }}
        >
          <th
            style={{
              textAlign: "center",
              fontWeight: "bold",
              border: "1px solid #ccc",
              padding: "8px",
            }}
            rowSpan={2}
          >
            No
          </th>
          <th
            style={{
              textAlign: "center",
              fontWeight: "bold",
              border: "1px solid #ccc",
              padding: "8px",
            }}
            rowSpan={2}
          >
            ID ATM
          </th>
          <th
            style={{
              textAlign: "center",
              fontWeight: "bold",
              border: "1px solid #ccc",
              padding: "8px",
            }}
            rowSpan={2}
          >
            Nama ATM
          </th>
          {generateDateLabels(startDate, daysInRange).map((label, index) => (
            <th
              key={index}
              colSpan={3}
              style={{
                textAlign: "center",
                fontWeight: "bold",
                border: "1px solid #ccc",
                padding: "8px",
              }}
            >
              {label}
            </th>
          ))}
        </tr>
        <tr
          style={{
            position: "sticky",
            top: 80, // Adjust this based on cumulative header height
            backgroundColor: "#f5f5f5", // Match header background
            zIndex: 1,
          }}
        >
          {Array.from({ length: daysInRange }, (_, index) => (
            <>
              <th
                key={`sub-1-${index}`}
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  border: "1px solid #ccc",
                  padding: "8px",
                }}
              >
                1
              </th>
              <th
                key={`sub-2-${index}`}
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  border: "1px solid #ccc",
                  padding: "8px",
                }}
              >
                2
              </th>
              <th
                key={`sub-3-${index}`}
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  border: "1px solid #ccc",
                  padding: "8px",
                }}
              >
                3
              </th>
            </>
          ))}
        </tr>
      </>
    );
  };

  const renderTableBody = () => {
    return reportExports.map((item, index) => {
      const { asset, laporans } = item;
      const { kode, name, ring, just_on_holiday } = asset;

      return (
        <tr key={index}>
          <td
            style={{
              textAlign: "center",
              border: "1px solid #ccc",
              padding: "8px",
            }}
          >
            {index + 1}
          </td>
          <td
            style={{
              textAlign: "left",
              border: "1px solid #ccc",
              padding: "8px",
            }}
          >
            {kode}
          </td>
          <td
            style={{
              textAlign: "left",
              border: "1px solid #ccc",
              padding: "8px",
            }}
          >
            {name}
          </td>
          {Array.from({ length: daysInRange }, (_, dayIndex) => {
            const date = moment(startDate)
              .add(dayIndex, "days")
              .format("YYYY-MM-DD");
            const dataLaporan = laporans?.[date] || [];

            return Array.from({ length: 3 }, (_, laporanIndex) => {
              let backgroundColor = "#FF0000";
              if (!laporans?.[date] && just_on_holiday)
                backgroundColor = "#FFFF00";
              if (laporanIndex >= parseInt(ring, 10))
                backgroundColor = "#000000";

              const laporan = dataLaporan[laporanIndex];
              if (laporan?.is_temuan !== undefined) {
                backgroundColor = laporan.is_temuan ? "#0070C0" : "#92D050";
              }

              return (
                <td
                  key={`${dayIndex}-${laporanIndex}`}
                  style={{
                    backgroundColor,
                    color: backgroundColor === "#000000" ? "#fff" : "#000",
                    textAlign: "center",
                    border: "1px solid #ccc",
                    padding: "8px",
                  }}
                >
                  {laporan?.date_done
                    ? moment.utc(laporan.date_done).format("HH:mm")
                    : ""}
                </td>
              );
            });
          })}
        </tr>
      );
    });
  };

  return (
    <div className="align-items-center py-4 w-100">
      <Card title={`Laporan Cabang ${query_pel || "Unknown"}`} bordered={false}>
        {isLoading ? (
          <div style={{ textAlign: "center", padding: "20px" }}>
            <Spin size="large" />
          </div>
        ) : id_pelanggan === null ? (
          <Alert
            message="Cabang tidak ditemukan. Silakan hubungi supervisor."
            type="warning"
          />
        ) : (
          <>
            <LaporanInformationCard />
            <Divider />
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>{renderTableHeaders()}</thead>
              <tbody>{renderTableBody()}</tbody>
            </table>
          </>
        )}
      </Card>
    </div>
  );
}
