import React, { useState } from "react";
import SimpleBar from "simplebar-react";
import { Route, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import {
  PieChart,
  LogOut,
  X,
  User,
  Building,
  Database,
  Link as LucideLink,
  File,
  List,
  Book,
  Lock as UserLock,
  Download,
  Archive,
  Coins,
  Menu,
} from "lucide-react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Nav, Badge, Image, Button, Navbar } from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";

import { Routes } from "../routes";
import GmeLogo from "../assets/img/icons/gme-icon.svg";
// import ProfilePicture from "../assets/img/team/profile-picture-3.jpg";
import { getLoginData, removeLoginData } from "../services/authSvc";
import { DashboardTwoTone, MenuUnfoldOutlined } from "@ant-design/icons";

export default (props = {}) => {
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";
  const { user } = getLoginData();

  const onCollapse = () => setShow(!show);

  const handleLogout = () => {
    removeLoginData();
    window.location.href = "/login";
  };

  const NavItem = (props) => {
    const {
      title,
      link,
      external,
      target,
      icon,
      image,
      badgeText,
      badgeBg = "secondary",
      badgeColor = "primary",
    } = props;
    const classNames = badgeText
      ? "d-flex justify-content-start align-items-center justify-content-between"
      : "";

    // Pengecekan untuk menentukan apakah "NavItem" aktif
    let isActive = link === pathname;

    // Tambahan pengecekan khusus untuk "Infografis Laporan"
    if (title === "Infografis Laporan") {
      isActive =
        pathname === Routes.ReportSummary.path ||
        pathname === Routes.AssetSummary.path ||
        pathname === Routes.OperatorSummary.path;
    }

    if (title === "Pengguna") {
      isActive =
        pathname === Routes.User.path ||
        pathname === Routes.User.path + "/history";
      // pathname === Routes.OperatorSummary.path;
    }

    const navItemClassName = isActive ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon && <span className="sidebar-icon">{icon}</span>}
            {image ? (
              <Image
                src={image}
                width={20}
                height={20}
                className="sidebar-icon svg-icon"
              />
            ) : null}

            <span className="sidebar-text text-sm">{title}</span>
          </span>
          {badgeText ? (
            <Badge
              pill
              bg={badgeBg}
              text={badgeColor}
              className="badge-md notification-count ms-2"
            >
              {badgeText}
            </Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        className={`navbar-light px-4 py-2 d-md-none d-flex justify-content-between ${
          show ? "position-static" : "position-sticky top-0"
        }`}
        style={
          !show
            ? {
                zIndex: 1000,
                backgroundColor: "#FFFFFF",
                // borderRadius: "8px",
                boxShadow: "0 4px 2px -2px rgba(0, 0, 0, 0.1)",
              }
            : {}
        }
      >
        <div className="d-flex align-items-center">
          {/* <Navbar.Brand
            className="me-lg-5"
            as={Link}
            to={Routes.DashboardOverview.path}
          >
            <Image src={ReactHero} className="navbar-brand-light" />
          </Navbar.Brand> */}
          <img
            src={GmeLogo}
            alt="logo"
            className="my-2"
            style={{ height: "30px" }}
          />
        </div>
        <MenuUnfoldOutlined onClick={onCollapse} style={{ fontSize: "28px" }} />
        {/* <Navbar.Toggle
          as={Button}
          aria-controls="main-navbar"
          onClick={onCollapse}
          style={{ border: "none !important" }}
        >
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle> */}
      </Navbar>

      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          className={`collapse ${showClass} sidebar d-md-block text-white`}
          // style={{ backgroundColor: "#ffe4b5" }}
        >
          <div
            style={{
              position: "sticky",
              top: 0,
              zIndex: 1000,
              backgroundColor: "#FFFFFF",
              // borderBottom:
              //   "0.0625rem solid rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;",
            }}
            className="py-3 px-4 "
          >
            {/* User Card Section */}
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-2">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  {user.photo_url ? (
                    <Image
                      src={user.photo_url}
                      className="user-avatar md-avatar rounded-circle"
                    />
                  ) : (
                    <div className="user-avatar md-avatar rounded-circle bg-primary">
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        {user.name.charAt(0).toUpperCase()}
                      </span>
                    </div>
                  )}
                </div>
                <div className="d-flex align-items-center">
                  <h6 className="text-dark mb-0 me-3">Hi, {user.name}</h6>
                  <Button
                    onClick={handleLogout}
                    variant="primary"
                    size="xs"
                    className="text-light"
                  >
                    <LogOut size={16} className="me-2" />
                    Sign Out
                  </Button>
                </div>
              </div>
              <Nav.Link
                className="collapse-close d-md-none text-dark"
                onClick={onCollapse}
              >
                <X
                  size={30}
                  onClick={onCollapse}
                  className="cursor-pointer"
                  style={{
                    transition: "color 0.3s",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.color = "#f76617")
                  }
                  onMouseLeave={(e) => (e.currentTarget.style.color = "")}
                />
              </Nav.Link>
            </div>

            {/* Logo and App Name Section */}
            <div
              className="d-flex gap-2 align-items-center justify-content-center text-center pt-3"
              style={{
                backgroundColor: "#FFFFFF",
              }}
            >
              <img
                src={GmeLogo}
                alt="logo"
                style={{ width: "70px", objectFit: "contain" }}
              />
              <h6 className="text-dark m-0">GME Monitoring</h6>
            </div>
          </div>

          <div className="sidebar-inner px-4 ">
            <hr className="mt-2 border-dark px-4" />
            <Nav className="flex-column pt-3 pt-md-0 pb-5">
              {/* <NavItem title="Volt React" link={Routes.Presentation.path} image={ReactHero} /> */}
              {/* <div
                className="d-flex gap-2 align-items-center justify-content-center text-center py-1"
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 1000,
                }}
              >
                <img
                  src={GmeLogo}
                  alt="logo"
                  style={{ width: "70px", objectFit: "contain" }}
                />
                <h6 className="text-dark m-0">GME Monitoring</h6>
              </div> */}

              <div className="text-primary mb-2" style={{ fontSize: "14px" }}>
                <strong>Beranda</strong>
              </div>
              {user.role === "operator" ? (
                <>
                  <NavItem
                    title="Upload Laporan"
                    icon={<File size={16} />}
                    link={
                      Routes.OperatorLaporan.path ||
                      Routes.OperatorLaporan.path ||
                      Routes.OperatorLaporan.path
                    }
                  />
                  <NavItem
                    title="Token EMR"
                    icon={<Coins size={16} />}
                    link={Routes.OperatorEmr.path}
                  />
                </>
              ) : (
                ""
              )}

              {user.role !== "operator" ? (
                <>
                  <NavItem
                    title="Dashboard"
                    link={Routes.DashboardOverview.path}
                    icon={<PieChart size={16} />}
                  />
                  <NavItem
                    title="Laporan"
                    icon={<File size={16} />}
                    link={Routes.Report.path}
                  />
                  <NavItem
                    title="Infografis Laporan"
                    icon={<Download size={16} />}
                    link={
                      Routes.ReportSummary.path ||
                      Routes.AssetSummary.path ||
                      Routes.OperatorSummary.path
                    }
                  />
                  <NavItem
                    title="Download Laporan"
                    icon={<Download size={16} />}
                    link={Routes.Export.path}
                  />
                </>
              ) : (
                ""
              )}

              {user.role === "admin" || user.role === "admin cabang" ? (
                <NavItem
                  title="Ekspor Link Laporan"
                  icon={<File size={16} />}
                  link={Routes.ExportPDFAsset.path}
                />
              ) : (
                ""
              )}

              {user.role !== "operator" ? (
                <>
                  <hr className="mt-2 border-dark " />
                  <div
                    className="text-primary mb-2"
                    style={{ fontSize: "14px" }}
                  >
                    <strong>Kelola</strong>
                  </div>
                  <NavItem
                    title="ATM"
                    icon={<Database size={16} />}
                    link={Routes.Asset.path}
                  />
                  <NavItem
                    title="Pengguna"
                    icon={<User size={16} />}
                    link={
                      Routes.User.path ||
                      Routes.AssetSummary.path ||
                      Routes.OperatorSummary.path
                    }
                  />
                  {user.role === "admin" ? (
                    <>
                      <NavItem
                        title="Role User-ATM"
                        icon={<UserLock size={16} />}
                        link={Routes.UserAsset.path}
                      />
                      <NavItem
                        title="Kantor Cabang"
                        icon={<Building size={16} />}
                        link={Routes.Customer.path}
                      />
                      <NavItem
                        title="Jenis Dokumentasi"
                        icon={<Book size={16} />}
                        link={Routes.DocType.path}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}

              {user.role !== "operator" ? (
                <>
                  <hr className="mt-2 border-dark " />
                  <div
                    className="text-primary mb-2"
                    style={{ fontSize: "14px" }}
                  >
                    <strong>Transaksi</strong>
                  </div>
                  <NavItem
                    title="Transaksi"
                    icon={<Database size={16} />}
                    link={Routes.report.transaction.path}
                  />
                </>
              ) : (
                ""
              )}

              {user.role === "admin" ? (
                <>
                  <hr className="mt-2 border-dark " />
                  <div
                    className="text-primary mb-2"
                    style={{ fontSize: "14px" }}
                  >
                    <strong>EMR</strong>
                  </div>
                  <NavItem
                    title="EMR Tool"
                    icon={<LucideLink size={16} />}
                    link={Routes.EMR.path}
                  />

                  <NavItem
                    title="Daftar EMR"
                    icon={<List size={16} />}
                    link={Routes.EMRList.path}
                  />
                  <NavItem
                    title="Daftar EMR V2"
                    icon={<List size={16} />}
                    link={Routes.EMRV2List.path}
                  />
                </>
              ) : (
                ""
              )}
              {user.role === "admin" ? (
                <>
                  <hr className="mt-2 border-dark " />
                  <div
                    className="text-primary mb-2"
                    style={{ fontSize: "14px" }}
                  >
                    <strong>Data Logs</strong>
                  </div>
                  <NavItem
                    title="Logs"
                    icon={<Archive size={16} />}
                    link={Routes.Logs.path}
                  />
                </>
              ) : (
                ""
              )}
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
