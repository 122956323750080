import { Button, Dropdown, Image, Menu, Space, Tag, Tooltip } from "antd";
import { formatToRupiah, truncateAddress } from "../utils/Helper";
import moment from "moment";
import { EllipsisOutlined } from "@ant-design/icons";
import { getLoginData } from "../services/authSvc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faHouseMedicalCircleExclamation,
  faKey,
  faMapMarked,
  faPenToSquare,
  faPlus,
  faTrash,
  faUser,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";
moment.tz.setDefault("Asia/Bangkok");
const { user } = getLoginData();
const role = user?.role;

export const ColumnTransaction = (history, role, handleDelete, handleEdit) => {
  return [
    {
      title: "Nama Kegiatan",
      dataIndex: "name",
      key: "name",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Harga",
      dataIndex: "price",
      key: "price",
      render: (text) => <span>{formatToRupiah(text)}</span>,
    },
    {
      title: "Deskripsi",
      dataIndex: "description",
      key: "description",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "ATM",
      dataIndex: "asset",
      key: "asset",
      render: (text) => <span>{text?.name}</span>,
    },
    {
      title: "Cabang",
      dataIndex: "pelanggan",
      key: "pelanggan",
      render: (text) => <span>{text?.name}</span>,
    },
    {
      title: "Foto Sebelum",
      key: "beforeImages",
      render: (text, record) => {
        const beforeImages = record.report_transaction_images.filter(
          (img) => img.image_type === "before"
        );

        return (
          <div>
            {beforeImages.length > 0 ? (
              beforeImages.map((img, index) => (
                <Image
                  key={index}
                  width={60}
                  src={img.image_url}
                  style={{ paddingRight: "2px" }}
                  fallback="https://storage.googleapis.com/bni-gme/transaksi/2024-08/2024-08-12/3b072d70-58d1-11ef-a445-8b8613c4cc11.png"
                />
              ))
            ) : (
              <span>-</span>
            )}
          </div>
        );
      },
    },
    {
      title: "Foto Sesudah",
      key: "afterImages",
      render: (text, record) => {
        const afterImages = record.report_transaction_images.filter(
          (img) => img.image_type === "after"
        );

        return (
          <div>
            {afterImages.length > 0 ? (
              afterImages.map((img, index) => (
                <Image
                  key={index}
                  width={60}
                  src={img.image_url}
                  style={{ paddingRight: "2px" }}
                  fallback="https://storage.googleapis.com/bni-gme/transaksi/2024-08/2024-08-12/3b072d70-58d1-11ef-a445-8b8613c4cc11.png"
                />
              ))
            ) : (
              <span>-</span>
            )}
          </div>
        );
      },
    },

    {
      title: "Tanggal Dibuat",
      key: "createdAt",
      render: (text, record) => {
        const date = record.createdAt
          ? moment(record.createdAt).format("DD MMMM YYYY HH:mm:ss")
          : "-";

        return <div>{date}</div>;
      },
    },

    {
      title: "Tanggal Pengerjaan",
      key: "operation_date",
      render: (text, record) => {
        const date = record.operation_date
          ? moment(record.operation_date).format("DD MMMM YYYY HH:mm:ss")
          : "-";

        return <div>{date}</div>;
      },
    },
    {
      title: "Aksi",
      key: "actions",
      fixed: "right",
      render: (text, record) => {
        let items = [
          {
            key: "0",
            label: "Lihat",
            onClick: () => {
              history.push(
                `/report/transaction/view/${record.id_report_transaction}`
              );
            },
          },
          {
            key: "1",
            label: "Edit",
            onClick: () => handleEdit(record.id_report_transaction),
          },
          {
            key: "2",
            label: "Delete",
            danger: true,
            onClick: () => handleDelete(record.id_report_transaction),
          },
        ];

        if (role !== "admin") {
          items = [items[0]];
        }
        return (
          <Space size="middle">
            <Dropdown overlay={<Menu items={items} />}>
              <Button icon={<EllipsisOutlined />} />
            </Dropdown>
          </Space>
        );
      },
    },
  ];
};

export const ColumnLaporanTemuan = (handleDetaiLaporan) => {
  return [
    {
      title: "Nama Operator",
      dataIndex: "user_name",
      key: "namaOperator",
      render: (text, record) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {record.user_photo_url ? (
              <img
                src={record.user_photo_url}
                alt="profile-pict"
                width="40px"
                height="40px"
                style={{
                  borderRadius: "50%",
                  marginRight: "10px",
                }}
                loading="lazy"
              />
            ) : (
              <div
                className="bg-primary"
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  backgroundColor: "orange",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: "10px",
                }}
              >
                <span
                  style={{
                    color: "#fff",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {record.user_name.charAt(0)}
                </span>
              </div>
            )}
            <div>
              <span>{record.user_name}</span>
              <br />
              Operator
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "ATM",
      dataIndex: "asset_name",
      key: "atm",
      render: (text, record) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <span>{record.asset_name}</span>
          <br />
          {record.asset_kode}
        </div>
      ),
    },
    {
      title: "Lokasi",
      dataIndex: "asset_alamat",
      key: "lokasi",
      render: (text, record) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <span>{truncateAddress(record.asset_alamat)}</span>
          <br />
          {record.pelanggan_name}
        </div>
      ),
    },
    {
      title: "Kunjungan",
      dataIndex: "kunjungan",
      key: "kunjungan",
      render: (text) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textAlign: "center",
          }}
        >
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Keterangan",
      dataIndex: "temuan.keterangan",
      key: "keterangan",
      render: (text, data) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <span>{data.temuan.keterangan}</span>
        </div>
      ),
    },
    {
      title: "Foto",
      key: "beforeImages",
      render: (text, record) => {
        const imageUrls = [
          record.temuan.temuan1_url,
          record.temuan.temuan2_url,
          // Add more URLs if there are more images
        ];

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {imageUrls.length > 0 ? (
              imageUrls.map((url, index) =>
                url ? (
                  <Image
                    key={index}
                    height={50}
                    width={30}
                    src={url}
                    style={{ paddingRight: "2px" }}
                    fallback="https://storage.googleapis.com/bni-gme/transaksi/2024-08/2024-08-12/3b072d70-58d1-11ef-a445-8b8613c4cc11.png"
                  />
                ) : null
              )
            ) : (
              <span>-</span>
            )}
          </div>
        );
      },
    },
    {
      title: "Tanggal",
      dataIndex: "date_done",
      key: "tanggal",
      render: (text) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <span>{moment.utc(text).format("YYYY-MM-DD")}</span>
          <br />
          {moment.utc(text).format("HH:mm:ss")}
        </div>
      ),
    },
    {
      title: "Detail",
      key: "details",
      fixed: "right",
      render: (text, record) => {
        const handleClick = () => {
          handleDetaiLaporan(record.id_laporan);
        };

        return (
          <a
            onClick={(e) => {
              e.preventDefault(); // Prevent default link behavior
              handleClick(); // Call your custom function
            }}
            rel="noopener noreferrer"
            className="fw-bold fw-bold border-0"
          >
            Lihat
          </a>
        );
      },
    },
  ];
};
export const ColumnLaporan = (goToDetailLaporan) => {
  return [
    {
      title: "Nama Operator",
      dataIndex: "user_name",
      key: "user_name",
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {record.user_photo_url ? (
            <Image
              src={`${record.user_photo_url}`}
              alt="profile-pict"
              width={40}
              height={40}
              style={{ borderRadius: "50%", marginRight: "10px" }}
            />
          ) : (
            <div
              className="bg-primary"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                backgroundColor: "orange",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "10px",
              }}
            >
              <span
                style={{
                  color: "#fff",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                {record.user_name ? record.user_name.charAt(0) : ""}
              </span>
            </div>
          )}
          <div style={{ flex: 1 }}>
            <span className="fw-bold" style={{ display: "block" }}>
              {record.user_name}
            </span>
            Operator
          </div>
        </div>
      ),
    },
    {
      title: "ATM",
      dataIndex: "asset_name",
      key: "asset_name",
      render: (text, record) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <span className="fw-bold" style={{ display: "block" }}>
            {record.asset_name}
          </span>
          {record.asset_kode}
        </div>
      ),
    },
    {
      title: "Lokasi",
      dataIndex: "asset_alamat",
      key: "asset_alamat",
      render: (text, record) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <span className="fw-bold">{record.pelanggan_name}</span>
          <br />
          <span>{truncateAddress(record.asset_alamat)}</span>
        </div>
      ),
    },
    {
      title: "Kunjungan",
      dataIndex: "kunjungan",
      key: "kunjungan",
      align: "center",
      render: (text) => <span className="fw-bold">{text}</span>,
    },
    {
      title: "Temuan",
      dataIndex: "is_temuan",
      key: "is_temuan",
      render: (isTemuan) => (
        <span
          style={{
            padding: "0.25rem 0.75rem",
            borderRadius: "12px",
            backgroundColor: isTemuan ? "#FDEDEE" : "#E6F7E6", // Light background
            color: isTemuan ? "#CF1322" : "#389E0D", // Contrasting text color
            fontWeight: "500",
            display: "inline-block",
            textAlign: "center",
          }}
        >
          {isTemuan ? "Temuan" : "Tidak"}
        </span>
      ),
    },
    {
      title: "Tanggal",
      dataIndex: "date_done",
      key: "date_done",
      render: (date) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <span>
            {moment.utc(date).format("YYYY-MM-DD")}
            <br />
            {moment.utc(date).format("HH:mm:ss")}
          </span>
        </div>
      ),
    },
    {
      title: "Aksi",
      dataIndex: "id_laporan",
      key: "id_laporan",
      fixed: "right",
      render: (idLaporan) => (
        <a
          rel="noopener noreferrer"
          className="fw-bold"
          onClick={() => {
            goToDetailLaporan(idLaporan);
          }}
        >
          Lihat
        </a>
      ),
    },
  ];
};
export const ColumnUserAsset = (
  history,
  handleEditUser,
  handleDelete,
  handleManageUser
) => {
  return [
    {
      title: "Nama User Asset",
      dataIndex: "keterangan",
      key: "keterangan",
      render: (text) => (
        <span className="fw-bold" style={{ whiteSpace: "nowrap" }}>
          {text}
        </span>
      ),
    },
    {
      title: "Jumlah User",
      dataIndex: "users",
      key: "users",
      render: (users) => (
        <span className="" style={{ whiteSpace: "nowrap" }}>
          {users.length} Users
        </span>
      ),
    },
    {
      title: "Cabang-1",
      dataIndex: "pelanggan1",
      key: "pelanggan1",
      render: (pelanggan1) => <span>{pelanggan1 ? pelanggan1.name : "-"}</span>,
    },
    {
      title: "Cabang-2",
      dataIndex: "pelanggan2",
      key: "pelanggan2",
      render: (pelanggan2) => <span>{pelanggan2 ? pelanggan2.name : "-"}</span>,
    },
    {
      title: "Cabang-3",
      dataIndex: "pelanggan3",
      key: "pelanggan3",
      render: (pelanggan3) => <span>{pelanggan3 ? pelanggan3.name : "-"}</span>,
    },
    {
      title: "Cabang-4",
      dataIndex: "pelanggan4",
      key: "pelanggan4",
      render: (pelanggan4) => <span>{pelanggan4 ? pelanggan4.name : "-"}</span>,
    },
    {
      title: "Cabang-5",
      dataIndex: "pelanggan5",
      key: "pelanggan5",
      render: (pelanggan5) => <span>{pelanggan5 ? pelanggan5.name : "-"}</span>,
    },
    {
      title: "Aksi",
      key: "action",
      fixed: "right",
      render: (userAsset) => {
        const items = [
          // {
          //   key: "0",
          //   label: "Lihat Detail",
          //   icon: <FontAwesomeIcon icon={faEye} />,
          //   onClick: () => {
          //     history.push(`/asset/detail/${asset.id_asset}`);
          //   },
          // },
        ];

        items.push(
          {
            key: "1",
            label: "Ubah Data User Asset",
            icon: <FontAwesomeIcon icon={faPenToSquare} />,
            onClick: () => {
              history.push(`/user-asset/${userAsset.id_user_asset}`);
            },
          },
          {
            key: "2",
            label: "Delete User Asset",
            icon: <FontAwesomeIcon icon={faTrash} />,
            onClick: () => {
              handleDelete(userAsset.id_user_asset);
            },
          },
          {
            key: "3",
            label: "Manage User Pengguna",
            icon: <FontAwesomeIcon icon={faUser} />,
            onClick: () => {
              history.push(`/user-asset/users/${userAsset.id_user_asset}`);
            },
          }
        );

        return (
          <Space size="middle">
            <Dropdown overlay={<Menu items={items} />}>
              <Button icon={<EllipsisOutlined />} />
            </Dropdown>
          </Space>
        );
      },
    },
    // {
    //   title: "Aksi",
    //   dataIndex: "id_user_asset",
    //   key: "id_user_asset",
    //   render: (userA) => (
    //     <div style={{ display: "flex", gap: "0.5rem" }}>
    //       <Button
    //         variant="secondary"
    //         size="sm"
    //         onClick={() => handleEditUser(id_user_asset.id_)}
    //       >
    //         Update
    //       </Button>
    //       <Button
    //         variant="danger"
    //         size="sm"
    //         onClick={() => handleDelete(id_user_asset)}
    //       >
    //         Delete
    //       </Button>
    //       <Button
    //         variant="info"
    //         size="sm"
    //         onClick={() => handleManageUser(id_user_asset)}
    //       >
    //         Manage Pengguna
    //       </Button>
    //     </div>
    //   ),
    // },
  ];
};

export const ColumnAsset = (
  history,
  role,
  dev,
  handleActive,
  handleActiveGPS
) => {
  return [
    {
      title: "Nama ATM",
      dataIndex: "name",
      key: "name",
      render: (text, asset) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <span className="fw-bold">{asset?.name}</span>
          <br />
          {asset?.kode}
        </div>
      ),
    },
    {
      title: "Lokasi",
      dataIndex: "lokasi",
      key: "lokasi",
      render: (text, asset) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <span className="fw-bold">{asset.lokasi}</span>
          <br />
          {truncateAddress(asset?.alamat)}
        </div>
      ),
    },
    {
      title: "Kepemilikan",
      dataIndex: "pelanggan",
      key: "pelanggan",
      render: (text, asset) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <span className="fw-bold">
            {asset?.pelanggan?.name.split(" ").slice(0, -1).join(" ")}
          </span>
          <br />
          <span className="fw-bold">
            {asset?.pelanggan?.name?.split(" ").slice(-1)}
          </span>
        </div>
      ),
    },
    {
      title: "Ring",
      dataIndex: "ring",
      key: "ring",
      render: (text) => <span className="fw-bold">{text}</span>,
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      render: (active) => (
        <span
          style={{
            padding: "0.25rem 0.75rem",
            borderRadius: "12px",
            backgroundColor: active ? "#E6F7E6" : "#FDEDEE", // Light background
            color: active ? "#389E0D" : "#CF1322", // Contrasting text color
            fontWeight: "500",
            display: "inline-block",
            textAlign: "center",
          }}
        >
          {active ? "Aktif" : "Nonaktif"}
        </span>
      ),
    },
    {
      title: "GPS",
      dataIndex: "gps_active",
      key: "gps_active",
      render: (gps_active) => (
        <span
          style={{
            padding: "0.25rem 0.75rem",
            borderRadius: "12px",
            backgroundColor: gps_active ? "#E6F7E6" : "#FDEDEE", // Light background
            color: gps_active ? "#389E0D" : "#CF1322", // Contrasting text color
            fontWeight: "500",
            display: "inline-block",
            textAlign: "center",
          }}
        >
          {gps_active ? "Aktif" : "Nonaktif"}
        </span>
      ),
    },

    {
      title: "Aksi",
      key: "action",
      fixed: "right",
      render: (text, asset) => {
        const items = [
          {
            key: "0",
            label: "Lihat Detail",
            icon: <FontAwesomeIcon icon={faEye} />,
            onClick: () => {
              history.push(`/asset/detail/${asset.id_asset}`);
            },
          },
        ];

        if (role === "admin" && dev === "developer") {
          items.push(
            {
              key: "1",
              label: "Ubah Data Asset",
              icon: <FontAwesomeIcon icon={faPenToSquare} />,
              onClick: () => {
                history.push(`/asset/update/${asset.id_asset}`);
              },
            },
            {
              key: "2",
              label: asset.active ? "Nonaktifkan" : "Aktifkan",
              icon: <FontAwesomeIcon icon={faWrench} />,
              onClick: () => {
                handleActive(asset.id_asset);
              },
            },
            {
              key: "3",
              label: asset.gps_active ? "Nonaktifkan GPS" : "Aktifkan GPS",
              icon: <FontAwesomeIcon icon={faMapMarked} />,
              onClick: () => {
                handleActiveGPS(asset.id_asset);
              },
            }
          );
        }

        return (
          <Space size="middle">
            <Dropdown overlay={<Menu items={items} />}>
              <Button icon={<EllipsisOutlined />} />
            </Dropdown>
          </Space>
        );
      },
    },
  ].filter(Boolean);
};

export const ColumnUser = (
  history,
  handleEditUser,
  handleResetPasswordEmail,
  handleActive,
  role,
  dev
) => {
  return [
    {
      title: "Nama Pengguna",
      dataIndex: "name",
      key: "name",
      render: (text, user) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {user.photo_url ? (
            <img
              src={user.photo_url}
              alt="profile-pict"
              width="40px"
              height="40px"
              style={{
                borderRadius: "50%",
                marginRight: "10px",
                objectFit: "cover",
              }}
            />
          ) : (
            <div
              className="bg-primary"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "10px",
              }}
            >
              <span
                style={{ color: "#fff", fontSize: "16px", fontWeight: "bold" }}
              >
                {user.name?.charAt(0).toUpperCase()}
              </span>
            </div>
          )}
          <div>
            <span className="fw-bold">{user.name}</span>
            <br />
            <span>{user.email}</span>
          </div>
        </div>
      ),
    },
    {
      title: "Nomor Telepon",
      dataIndex: "phone",
      key: "phone",
      render: (phone) => <span className="fw-bold">{phone}</span>,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (role, user) => (
        <div>
          <span className="fw-bold">{role.toUpperCase()}</span>
          {user.pelanggan && (
            <>
              <br />
              <span>{user.pelanggan.name}</span>
            </>
          )}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      render: (active) => (
        <span
          style={{
            padding: "0.25rem 0.75rem",
            borderRadius: "12px",
            backgroundColor: active ? "#E6F7E6" : "#FDEDEE",
            color: active ? "#389E0D" : "#CF1322",
            fontWeight: "500",
            display: "inline-block",
            textAlign: "center",
          }}
        >
          {active ? "Aktif" : "Nonaktif"}
        </span>
      ),
    },
    {
      title: "Aksi",
      key: "aksi",
      fixed: "right",
      render: (text, user) => {
        const items = [
          {
            key: "0",
            label: "Lihat Detail",
            icon: <FontAwesomeIcon icon={faEye} />,
            onClick: () => {
              history.push(`/user/detail/${user.id_user}`);
            },
          },
        ];

        if (role === "admin" && dev === "developer") {
          items.push(
            {
              key: "1",
              label: "Reset Password",
              icon: <FontAwesomeIcon icon={faKey} />,
              onClick: () => handleResetPasswordEmail(user.id_user),
            },
            {
              key: "2",
              label: "Ubah Data Pengguna",
              icon: <FontAwesomeIcon icon={faPenToSquare} />,
              onClick: () => handleEditUser(user.id_user),
            },
            {
              key: "3",
              label: user.active ? "Nonaktifkan" : "Aktifkan",
              icon: <FontAwesomeIcon icon={faWrench} />,
              onClick: () => handleActive(user.id_user),
            }
          );
        }

        return (
          <Space size="middle">
            <Dropdown overlay={<Menu items={items} />}>
              <Button icon={<EllipsisOutlined />} />
            </Dropdown>
          </Space>
        );
      },
    },
  ].filter(Boolean); // Remove null/undefined columns
};

export const ColumnPelanggan = (
  history,
  handleEditCustomer,
  handleActive,
  role,
  dev
) => {
  return [
    {
      title: "Nama Pelanggan",
      dataIndex: "name",
      key: "name",
      render: (text, customer) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {customer.photo_url ? (
            <img
              src={customer.photo_url}
              alt="profile-pict"
              width="40px"
              height="40px"
              style={{
                borderRadius: "50%",
                marginRight: "10px",
                objectFit: "cover",
              }}
            />
          ) : (
            <div
              className="bg-primary"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "10px",
              }}
            >
              <span
                style={{ color: "#fff", fontSize: "16px", fontWeight: "bold" }}
              >
                {customer.name?.charAt(0).toUpperCase()}
              </span>
            </div>
          )}
          <div>
            <span className="fw-bold">{customer.name}</span>
            <br />
            <span>{customer.email}</span>
          </div>
        </div>
      ),
    },
    {
      title: "Lokasi",
      dataIndex: "alamat",
      key: "alamat",
      render: (alamat, customer) => (
        <div>
          <span className="fw-bold">{truncateAddress(alamat)}</span>
          <br />
          {customer.phone}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      render: (active) => (
        <span
          style={{
            padding: "0.25rem 0.75rem",
            borderRadius: "12px",
            backgroundColor: active ? "#E6F7E6" : "#FDEDEE",
            color: active ? "#389E0D" : "#CF1322",
            fontWeight: "500",
            display: "inline-block",
            textAlign: "center",
          }}
        >
          {active ? "Aktif" : "Nonaktif"}
        </span>
      ),
    },
    {
      title: "Pengguna",
      key: "totalUser",
      render: (text, customer) => (
        <div>
          <span className="fw-bold">Total Pengguna:</span> {customer.totalUser}
          <br />
          <span className="fw-bold">Aktif:</span> {customer.totalUserActive} |
          <span className="fw-bold"> Nonaktif:</span>{" "}
          {customer.totalUserInactive}
        </div>
      ),
    },
    {
      title: "ATM",
      key: "totalAsset",
      render: (text, customer) => (
        <div>
          <span className="fw-bold">Total ATM:</span> {customer.totalAsset}
          <br />
          <span className="fw-bold">Aktif:</span> {customer.totalAssetActive} |
          <span className="fw-bold"> Nonaktif:</span>{" "}
          {customer.totalAssetInactive}
        </div>
      ),
    },
    {
      title: "Aksi",
      key: "aksi",
      fixed: "right",
      render: (text, customer) => {
        const items = [
          {
            key: "0",
            label: "Lihat Detail",
            icon: <FontAwesomeIcon icon={faEye} />,
            onClick: () => {
              history.push(`/customer/detail/${customer.id_pelanggan}`);
            },
          },
        ];

        if (role === "admin" && dev === "developer") {
          items.push(
            {
              key: "1",
              label: "Ubah Data Pelanggan",
              icon: <FontAwesomeIcon icon={faPenToSquare} />,
              onClick: () => handleEditCustomer(customer.id_pelanggan),
            },
            {
              key: "2",
              label: customer.active ? "Nonaktifkan" : "Aktifkan",
              icon: <FontAwesomeIcon icon={faWrench} />,
              onClick: () => handleActive(customer.id_pelanggan),
            }
          );
        }

        return (
          <Space size="middle">
            <Dropdown overlay={<Menu items={items} />} trigger={["click"]}>
              <Button icon={<EllipsisOutlined />} />
            </Dropdown>
          </Space>
        );
      },
    },
  ].filter(Boolean); // Filters out null/undefined columns
};

export const ColumnJenisDokumentasi = (
  history,
  handleEdit,
  handleDelete,
  handleActive,
  role,
  dev,
  customers
) => {
  return [
    {
      title: "Nama Dokumentasi",
      dataIndex: "nama_jenis_dokumentasi",
      key: "nama_jenis_dokumentasi",
      render: (name) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Tooltip title={name}>
            <span style={{ fontWeight: "500", fontSize: "14px" }}>{name}</span>
          </Tooltip>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      render: (active) => {
        const color = active ? "#389E0D" : "#CF1322";
        const bgColor = active ? "#E6F7E6" : "#FDEDEE";
        return (
          <Tag style={{ backgroundColor: bgColor, color, fontWeight: "500" }}>
            {active ? "Aktif" : "Nonaktif"}
          </Tag>
        );
      },
    },
    {
      title: "Tipe",
      dataIndex: "type_doc",
      key: "type_doc",
      render: (type_doc) => (
        <Tag
          color={type_doc === "image" ? "blue" : "purple"}
          style={{ fontWeight: "500", textTransform: "uppercase" }}
        >
          {type_doc}
        </Tag>
      ),
    },
    {
      title: "Digunakan Oleh",
      dataIndex: "id_pelanggan",
      key: "id_pelanggan",
      render: (id_pelanggan) => {
        if (
          !id_pelanggan ||
          !Array.isArray(id_pelanggan) ||
          id_pelanggan.length === 0
        ) {
          return <Tag color="default">Default</Tag>;
        }

        // Filter customers by matching id_pelanggan and extract names.
        const names = customers
          .filter((customer) => id_pelanggan.includes(customer.id_pelanggan))
          .map((customer) => customer.name);

        // Display first two names; if there are more, indicate with ellipsis.
        const displayNames =
          names.length > 2
            ? `${names.slice(0, 2).join(", ")} ... (+${
                names.length - 2
              } others)`
            : names.join(", ");

        return (
          <Tooltip title={names.join(", ")}>
            <span style={{ fontWeight: "500" }}>{displayNames}</span>
          </Tooltip>
        );
      },
    },
    {
      title: "Aksi",
      key: "aksi",
      fixed: "right",
      render: (text, doc) => {
        const items = [];

        if (role === "admin" && dev === "developer") {
          items.push(
            {
              key: "0",
              label: "Hapus",
              icon: <FontAwesomeIcon icon={faEye} />,
              onClick: () => handleDelete(doc.id_jenis_dokumentasi),
            },
            {
              key: "1",
              label: "Ubah Data",
              icon: <FontAwesomeIcon icon={faPenToSquare} />,
              onClick: () => handleEdit(doc.id_jenis_dokumentasi),
            },
            {
              key: "2",
              label: doc.active ? "Nonaktifkan" : "Aktifkan",
              icon: <FontAwesomeIcon icon={faWrench} />,
              onClick: () => handleActive(doc.id_jenis_dokumentasi),
            }
          );
        }

        return (
          <Space size="middle">
            <Dropdown overlay={<Menu items={items} />} trigger={["click"]}>
              <Button icon={<EllipsisOutlined />} />
            </Dropdown>
          </Space>
        );
      },
    },
  ].filter(Boolean);
};

export const ColumnHistoryPengguna = () => {
  return [
    {
      title: "No",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Nama Pengguna",
      dataIndex: "name",
      key: "name",
      render: (name, user) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {user.photo_url ? (
            <Image
              src={user.photo_url}
              alt="profile-pict"
              width={40}
              height={40}
              style={{
                borderRadius: "50%",
                marginRight: "10px",
                objectFit: "cover",
              }}
            />
          ) : (
            <div
              className="bg-primary"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "10px",
              }}
            >
              <span
                style={{
                  color: "#fff",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                {name?.charAt(0).toUpperCase()}
              </span>
            </div>
          )}
          <div>
            <span className="fw-bold">{name}</span>
            <br />
            {user.email}
          </div>
        </div>
      ),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (role, user) => (
        <div>
          {role && <span className="fw-bold">{role.toUpperCase()}</span>}
          {user?.pelanggan && (
            <>
              <br />
              <span>{user.pelanggan.name}</span>
            </>
          )}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      render: (active) => (
        <span
          style={{
            padding: "0.25rem 0.75rem",
            borderRadius: "12px",
            backgroundColor: active ? "#E6F7E6" : "#FDEDEE", // Light background
            color: active ? "#389E0D" : "#CF1322", // Contrasting text color
            fontWeight: "500",
            display: "inline-block",
            textAlign: "center",
          }}
        >
          {active ? "Aktif" : "Nonaktif"}
        </span>
      ),
    },
    {
      title: "Terakhir Aktif",
      dataIndex: "last_login",
      key: "updatedAt",
      render: (last_login) =>
        last_login ? (
          <div>
            <span className="fw-bold">
              {new Date(last_login).toLocaleDateString("id-ID", {
                timeZone: "Asia/Bangkok",
                day: "numeric",
                month: "long",
                year: "numeric",
              })}
            </span>
            <br />
            <span className="fw-bold">
              {new Date(last_login).toLocaleTimeString("id-ID", {
                timeZone: "Asia/Bangkok",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
              })}
            </span>
          </div>
        ) : (
          "-"
        ),
    },
    {
      title: "Informasi",
      key: "informasi",
      render: (_, user) => (
        <div>
          <span className="fw-bold">{user?.last_ip}</span>
          {/* {user?.pelanggan && (
            <>
              <br />
              <span>{user?.pelanggan?.name}</span>
            </>
          )} */}
        </div>
      ),
    },
  ];
};
