import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faHouseMedicalCircleExclamation,
  faMapMarked,
  faPenToSquare,
  faPlus,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";

import {
  // Button,
  Card,
  // Table,
  Form,
  InputGroup,
  Spinner,
  // Pagination,
  Nav,
  FormControl,
} from "@themesberg/react-bootstrap";
// import Select from "react-select";

import { useEffect } from "react";
import Swal from "sweetalert2";
import FormAsset from "./FormAsset";
import assetSvc from "../../services/assetSvc";
import customerSvc from "../../services/customerSvc";
import { useHistory, useLocation } from "react-router-dom";
import { getLoginData } from "../../services/authSvc";
import docTypeSvc from "../../services/docTypeSvc";
import {
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../utils/SwalUtil";
import { customStyles } from "../../utils/SelectUtils";
import {
  Card as CardAntd,
  Input,
  Tooltip,
  Select,
  Button,
  Tag,
  Table,
  Space,
  Menu,
  Dropdown,
  Badge,
  Pagination,
  Spin,
} from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EllipsisOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import qs from "query-string";
import { ColumnAsset } from "../../template/TemplateColumn";
import DataTable from "../../components/template/DataTable";

const { Option } = Select;
export default () => {
  const location = useLocation();
  const history = useHistory();
  const query = qs.parse(location.search);

  const searchInputRef = useRef(null);
  const [assets, setAssets] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);

  const [page, setPage] = useState(query.page ? parseInt(query.page) : 1);
  const [pageSize, setPageSize] = useState(
    query.limit ? parseInt(query.limit) : 10
  );
  const [searchTerm, setSearchTerm] = useState(query.query || "");
  const [selectedStatus, setSelectedStatus] = useState(
    query.active === "true" ? "true" : query.active === "false" ? "false" : ""
  );
  const [selectedCustomer, setSelectedCustomer] = useState(
    query.id_pelanggan || null
  );

  const { user } = getLoginData();
  const role = user?.role;
  const dev = process.env.REACT_APP_UI_DEV;

  const updateURLParams = () => {
    const params = {
      query: searchTerm || undefined,
      active: selectedStatus || undefined,
      id_pelanggan: selectedCustomer || undefined,
      page,
      limit: pageSize,
    };
    history.replace({
      pathname: location.pathname,
      search: qs.stringify(params),
    });
  };

  useEffect(() => {
    updateURLParams();
  }, [searchTerm, selectedStatus, selectedCustomer, page, pageSize]);

  const fetchAssets = async () => {
    setIsLoading(true);
    const resp = await assetSvc.getAsset({
      params: {
        page: page - 1,
        limit: pageSize,
        query: searchTerm ? searchTerm : null,
        active: selectedStatus,
        id_pelanggan: selectedCustomer ? selectedCustomer : null,
        fields: undefined,
        includes: JSON.stringify([
          {
            model: "pelanggan",
            attributes: ["name"],
          },
        ]),
      },
    });
    if (resp.status === 200) {
      setAssets(resp.data.data);
      setTotalRows(resp.data.totalRows);
    }

    setIsLoading(false);
  };

  const fetchCustomers = async () => {
    if (user?.role === "admin") {
      try {
        // setIsLoading(true);
        const response = await customerSvc.getCustomer({
          params: { page: 0, limit: 1000 },
        });
        if (response.status === 200) {
          setCustomers(response.data.data);
        } else {
          setCustomers([]);
        }
      } catch (error) {
        console.error("Failed to fetch customers:", error);
      } finally {
        // setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    // fetchCustomers();
  }, []);

  const isFirstRenderAssets = useRef(true);
  useEffect(() => {
    if (!isFirstRenderAssets.current) {
      fetchAssets();
    } else {
      isFirstRenderAssets.current = false;
    }
  }, []);

  useEffect(() => {
    fetchAssets();
  }, [page]);

  useEffect(() => {
    // setPage(1);
    fetchAssets();
  }, [selectedCustomer, selectedStatus, pageSize]);

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (!isFirstRender.current) {
      setPage(1);
      let timerId;
      const fetchData = () => {
        fetchAssets();
      };
      const delayedFetch = () => {
        clearTimeout(timerId);
        timerId = setTimeout(fetchData, 500);
      };
      delayedFetch();
      return () => {
        clearTimeout(timerId);
      };
    } else {
      isFirstRender.current = false;
    }
  }, [searchTerm]);

  const handleClearFilters = () => {
    setSearchTerm("");
    setSelectedStatus(null);
    setSelectedCustomer(null);
    if (searchInputRef.current) {
      searchInputRef.current.value = "";
    }
  };

  const handleActive = async (id) => {
    const willUpdate = await Swal.fire({
      title: "Konfirmasi Perubahan?",
      text: "Status asset akan berubah setelah update!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Lanjutkan!",
      cancelButtonText: "Batalkan",
    });

    if (willUpdate.isConfirmed) {
      try {
        showLoadingAlert("Tunggu", "Mengubah status aset...");
        const resp = await assetSvc.updateActivateAsset(id);
        if (resp.status === 200) {
          fetchAssets();
          showSuccessAlert("Berhasil", "Data status aset berhasil");
        }
      } catch (error) {
        let message =
          error.response.data.message || "Gagal mengubah status aset";
        showErrorAlert("Gagal", message);
      }
    }
  };

  const handleActiveGPS = async (id) => {
    const willUpdate = await Swal.fire({
      title: "Konfirmasi Perubahan?",
      text: "Status GPS ATM akan berubah setelah update!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Lanjutkan!",
      cancelButtonText: "Batalkan",
    });

    if (willUpdate.isConfirmed) {
      try {
        showLoadingAlert("Tunggu", "Mengubah status GPS...");
        const resp = await assetSvc.updateActivateAssetGPS(id);
        if (resp.status === 200) {
          fetchAssets();
          showSuccessAlert("Berhasil", "Data status GPS berhasil");
        }
      } catch (error) {
        let message =
          error.response.data.message || "Gagal mengubah status GPS";
        showErrorAlert("Gagal", message);
      }
    }
  };

  const handleAddAsset = () => {
    history.push("/asset/add");
  };

  const handleEditAsset = async (id) => {
    history.push(`/asset/update/${id}`);
  };

  const truncateAddress = (address) => {
    const maxLength = 50; // Set the maximum length for the address
    if (address.length <= maxLength) {
      return address;
    }
    return address.substring(0, maxLength) + "...";
  };

  const handleSelectCustomer = (selectedOption) => {
    setSelectedCustomer(selectedOption);
  };

  const handleDropdownVisibleChange = (open) => {
    if (open && customers.length === 0) {
      fetchCustomers(); // Fetch customers only when the dropdown opens for the first time
    }
  };

  const columns = ColumnAsset(
    history,
    role,
    dev,
    handleActive,
    handleActiveGPS
  );

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePageSizeChange = (current, pageSize) => {
    // setPage(1);
    setPageSize(pageSize);
  };

  const pagination = {
    showTotal: (total, range) =>
      `Showing ${range[0]}-${range[1]} of ${total} items`,
    pageSize: pageSize,
    current: page,
    total: totalRows,
    onChange: handlePageChange,
    onShowSizeChange: handlePageSizeChange,
    pageSizeOptions: [5, 10, 20],
    showSizeChanger: true,
    showQuickJumper: false,
    // position: ["bottomRight"],
    align: "center",
    // className: "custom-pagination",
  };

  return (
    <div className="align-items-center py-4 w-100">
      <CardAntd
        title={<span style={{ fontSize: "20px" }}>ATM</span>}
        extra={
          <div className="d-flex">
            {role === "admin" && dev === "developer" && (
              <div className="d-flex gap-2 ">
                <Tooltip title={"Tambah ATM"}>
                  <Button variant="success" size="sm" onClick={handleAddAsset}>
                    <FontAwesomeIcon
                      icon={faPlus}
                      style={{ fontSize: "14px" }}
                    />
                  </Button>
                </Tooltip>
              </div>
            )}
          </div>
        }
        style={{ width: "100%" }}
      >
        <div className="d-flex flex-wrap justify-content-between mb-3 gap-2">
          <Input.Group style={{ flexGrow: 1, flexBasis: "300px" }}>
            <Input
              size="middle"
              placeholder="Cari nama ATM, Lokasi, Kode, dll..."
              prefix={<SearchOutlined />}
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              className="me-1"
              ref={searchInputRef}
            />
          </Input.Group>

          <Input.Group style={{ flexGrow: 1, flexBasis: "300px" }}>
            <Select
              id="state"
              value={selectedStatus}
              onChange={(value) => setSelectedStatus(value)}
              placeholder="Semua Status"
              style={{ width: "100%", height: "100%" }}
              size="middle"
            >
              <Option value="">Semua Status</Option>
              <Option value="true">Status Aktif</Option>
              <Option value="false">Status Nonaktif</Option>
            </Select>
          </Input.Group>

          {user?.role === "admin" && (
            <Input.Group style={{ flexGrow: 1, flexBasis: "300px" }}>
              <Select
                placeholder="Semua Kantor Cabang"
                allowClear
                showSearch
                onDropdownVisibleChange={handleDropdownVisibleChange}
                onChange={handleSelectCustomer}
                value={selectedCustomer}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                style={{ width: "100%", height: "100%" }}
                size="middle"
                notFoundContent={
                  customers.length == 0 ? (
                    <Spin size="small" />
                  ) : (
                    "No Data Found"
                  )
                } // Display loading spinner or fallback message
              >
                {customers.map((data) => (
                  <Select.Option
                    key={data.id_pelanggan}
                    value={data.id_pelanggan}
                  >
                    {data.name}
                  </Select.Option>
                ))}
              </Select>
            </Input.Group>
          )}

          <Button
            variant="secondary"
            size="sm"
            className="ml-2"
            style={{ height: "auto" }}
            onClick={(e) => {
              handleClearFilters();
            }}
          >
            Reset Filter
          </Button>
        </div>
        <DataTable
          columns={columns}
          dataSource={assets}
          loading={isLoading}
          pagination={pagination}
        />
      </CardAntd>
    </div>
  );
};
