import React, { useEffect, useRef, useState } from "react";
import moment from "moment-timezone";

import reportSvc from "../services/reportSvc";
import userSvc from "../services/userSvc";
import customerSvc from "../services/customerSvc";
import assetSvc from "../services/assetSvc";
import { showErrorAlert } from "../utils/SwalUtil";
import { getLoginData } from "../services/authSvc";
import {
  Card as CardAntd,
  Input,
  Table,
  Select,
  Button,
  DatePicker,
  Skeleton,
  Image,
  Pagination,
} from "antd";
import dayjs from "dayjs";
import qs from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import { ColumnLaporan } from "../template/TemplateColumn";
import DataTable from "../components/template/DataTable";
import AsyncSelect from "../components/template/AsyncSelect";
const { Option } = Select;
const { RangePicker } = DatePicker;
moment.tz.setDefault("Asia/Bangkok");

export default () => {
  const searchInputRef = useRef(null);
  const [reports, setReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [users, setUsers] = useState([]);
  const [assets, setAssets] = useState([]);
  const history = useHistory();
  const { user } = getLoginData();
  const location = useLocation();

  const query = qs.parse(location.search);

  const [searchTerm, setSearchTerm] = useState(query.query || "");
  const [startDate, setStartDate] = useState(
    query.start_date || moment().format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    query.end_date || moment().format("YYYY-MM-DD")
  );
  const [selectedCustomer, setSelectedCustomer] = useState(
    query.id_pelanggan || null
  );
  const [selectedUser, setSelectedUser] = useState(query.id_user || null);
  const [selectedAsset, setSelectedAsset] = useState(query.id_asset || null);
  const [selectedTemuan, setSelectedTemuan] = useState(query.is_temuan || null);
  const [page, setPage] = useState(query.page ? parseInt(query.page) : 1);
  const [pageSize, setPageSize] = useState(
    query.limit ? parseInt(query.limit) : 10
  );
  const [totalRows, setTotalRows] = useState(0);

  const updateURLParams = () => {
    const queryParams = {
      query: searchTerm || undefined,
      start_date: startDate,
      end_date: endDate,
      id_pelanggan: selectedCustomer || undefined,
      id_user: selectedUser || undefined,
      id_asset: selectedAsset || undefined,
      is_temuan: selectedTemuan || undefined,
      page,
      limit: pageSize,
    };
    history.replace({
      pathname: location.pathname,
      search: qs.stringify(queryParams),
    });
  };

  useEffect(() => {
    updateURLParams();
  }, [
    searchTerm,
    startDate,
    endDate,
    selectedCustomer,
    selectedUser,
    selectedAsset,
    selectedTemuan,
    page,
    pageSize,
  ]);

  const handleChangeDate = (dates) => {
    if (dates && dates.length === 2) {
      setStartDate(dates[0].format("YYYY-MM-DD"));
      setEndDate(dates[1].format("YYYY-MM-DD"));
    }
  };

  const fetchReports = async () => {
    try {
      setIsLoading(true);
      const params = {
        page: page - 1,
        limit: pageSize,
        query: searchTerm,
        start_date: startDate,
        end_date: endDate,
        id_pelanggan: selectedCustomer,
        id_user: selectedUser,
        id_asset: selectedAsset,
        is_temuan: selectedTemuan,
      };

      const resp = await reportSvc.getReports({
        params: { ...params },
      });
      if (resp.status === 200) {
        setReports(resp.data.data);
        setTotalRows(resp.data.totalRows);
      }
    } catch (error) {
      let message =
        error.response?.data?.message || "Gagal mengambil data report";
      showErrorAlert("Gagal", message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAssets = async () => {
    try {
      // setIsLoadingFilter(true);
      const resp = await assetSvc.getAsset({
        params: {
          page: 0,
          limit: 1000,
          fields: `["name", "id_asset", "id_pelanggan"]`,
          includes: JSON.stringify([
            {
              model: "pelanggan",
              attributes: ["name"],
            },
          ]),
        },
      });
      if (resp.status === 200) {
        setAssets(resp.data.data);
      }
    } catch (error) {
    } finally {
      // setIsLoadingFilter(false);
    }
  };

  const fetchUser = async () => {
    try {
      // setIsLoadingFilter(true);
      const resp = await userSvc.getUser({
        params: {
          page: 0,
          limit: 1000,
          role: "operator",
          fields: `["name", "id_user"]`,
          includes: JSON.stringify([
            // {
            //   model: "pelanggan",
            //   attributes: ["name"],
            // },
          ]),
        },
      });
      if (resp.status === 200) {
        setUsers(resp.data.data);
      }
    } catch (error) {
    } finally {
      // setIsLoadingFilter(false);
    }
  };

  const fetchCustomers = async () => {
    try {
      // setIsLoadingFilter(true);
      const resp = await customerSvc.getCustomer({
        params: {
          page: 0,
          limit: 10000,
        },
      });
      if (resp.status === 200) {
        setCustomers(resp.data.data);
      }
    } catch (error) {
    } finally {
      // setIsLoadingFilter(false);
    }
  };

  useEffect(() => {
    if (getLoginData()?.user?.role === "admin") {
      // fetchCustomers();
    }
    // fetchUser();
    // fetchAssets();
  }, []);

  useEffect(() => {
    fetchReports();
  }, [
    startDate,
    endDate,
    selectedAsset,
    selectedCustomer,
    selectedTemuan,
    selectedUser,
    page,
    pageSize,
  ]);

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (!isFirstRender.current) {
      let timerId;

      const delayedFetch = () => {
        clearTimeout(timerId);
        timerId = setTimeout(() => {
          fetchReports();
        }, 500);
      };

      delayedFetch();

      return () => {
        clearTimeout(timerId);
      };
    } else {
      isFirstRender.current = false;
    }
  }, [searchTerm]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleClearFilters = () => {
    setSearchTerm(null);
    setSelectedUser(null);
    setSelectedAsset(null);
    setSelectedCustomer(null);
    setPage(1);
    setEndDate(moment().format("YYYY-MM-DD"));
    setSelectedTemuan(null);
    setStartDate(moment().format("YYYY-MM-DD"));

    if (searchInputRef.current) {
      searchInputRef.current.value = "";
    }
  };

  const goToDetailLaporan = (id) => {
    history.push(`/report-detail/${id}`);
  };

  const handlePageSizeChange = (current, pageSize) => {
    setPage(1);
    setPageSize(pageSize);
  };

  const columns = ColumnLaporan(goToDetailLaporan);

  const pagination = {
    showTotal: (total, range) =>
      `Showing ${range[0]}-${range[1]} of ${total} items`,
    pageSize: pageSize,
    total: totalRows,
    current: page,
    onChange: handlePageChange,
    onShowSizeChange: handlePageSizeChange,
    pageSizeOptions: [5, 10, 20],
    showSizeChanger: true,
    position: ["bottomRight"],
    className: "custom-pagination",
  };

  return (
    <div className="align-items-center py-4 w-100">
      <CardAntd
        title={<span style={{ fontSize: "20px" }}>Riwayat Laporan</span>}
        extra={<div className="d-flex"></div>}
        style={{ width: "100%" }}
      >
        {isLoadingFilter ? (
          <Skeleton.Input
            active
            block
            style={{ width: "100%", height: "5rem", marginBottom: "1rem" }}
          />
        ) : (
          <div className="d-flex flex-wrap justify-content-between mb-3 gap-2">
            <Input.Group style={{ flexGrow: 1, flexBasis: "300px" }}>
              <Input
                size="middle"
                placeholder="Cari operator, ATM / kode ATM.."
                prefix={<SearchOutlined />}
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
                className="me-1"
                ref={searchInputRef}
              />
            </Input.Group>

            <Input.Group style={{ flexGrow: 1, flexBasis: "300px" }}>
              <RangePicker
                style={{ width: "100%" }}
                size="middle"
                onChange={handleChangeDate}
                allowClear={false}
                format="YYYY-MM-DD"
                value={[
                  dayjs(startDate, "YYYY-MM-DD"),
                  dayjs(endDate, "YYYY-MM-DD"),
                ]}
              />
            </Input.Group>

            <Input.Group style={{ flexGrow: 1, flexBasis: "300px" }}>
              <Select
                id="state"
                value={selectedTemuan}
                onChange={(value) => {
                  setSelectedTemuan(value);
                }}
                placeholder="Semua Temuan"
                style={{ width: "100%", height: "100%" }}
                size="middle"
              >
                <Option value="true">Ada Temuan</Option>
                <Option value="false">Tidak ada temuan</Option>
              </Select>
            </Input.Group>

            {user?.role === "admin" && (
              <Input.Group style={{ flexGrow: 1, flexBasis: "300px" }}>
                <AsyncSelect
                  placeholder="Semua Kantor Cabang"
                  onDropdownVisibleChange={() =>
                    customers.length === 0 && fetchCustomers()
                  }
                  onChange={setSelectedCustomer}
                  value={selectedCustomer}
                  options={customers.map((data) => ({
                    key: data.id_pelanggan,
                    value: data.id_pelanggan,
                    label: data.name,
                  }))}
                  loading={customers.length === 0}
                />
              </Input.Group>
            )}

            <Input.Group style={{ flexGrow: 1, flexBasis: "300px" }}>
              <AsyncSelect
                placeholder="Semua Operator"
                onDropdownVisibleChange={() => {
                  if (users.length === 0) fetchUser();
                }}
                onChange={setSelectedUser}
                value={selectedUser}
                options={users.map((data) => ({
                  key: data.id_user,
                  value: data.id_user,
                  label: data.name,
                }))}
                loading={users.length === 0}
              />
            </Input.Group>

            <Input.Group style={{ flexGrow: 1, flexBasis: "300px" }}>
              <AsyncSelect
                placeholder="Semua ATM"
                onDropdownVisibleChange={() => {
                  if (assets.length === 0) fetchAssets();
                }}
                onChange={setSelectedAsset}
                value={selectedAsset}
                options={assets.map((data) => ({
                  key: data.id_asset,
                  value: data.id_asset,
                  label: `${data.name}- ${data.pelanggan.name}`,
                }))}
                loading={assets.length === 0}
              />
            </Input.Group>

            <Button
              variant="secondary"
              size="sm"
              className="ml-2"
              style={{ height: "auto" }}
              onClick={(e) => {
                handleClearFilters();
              }}
            >
              Reset Filter
            </Button>
          </div>
        )}

        <DataTable
          columns={columns}
          dataSource={reports}
          loading={isLoading}
          pagination={pagination}
        />
      </CardAntd>
    </div>
  );
};
